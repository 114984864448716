<template>
    <main >
        <h1 class="heading">{{$t('help.title')}}</h1>

        <div style="padding-right:40px;" v-if="!settings">
            <div class="help__questions">
                <v-expansion-panels accordion flat>
                    <v-expansion-panel>
                        <v-expansion-panel-header>How to order stickers or cards for rooms and reception?</v-expansion-panel-header>
                      <v-expansion-panel-content>Send us an <a href="mailto:guest@tourist.com">email</a> and tell us your wishes.</v-expansion-panel-content>
                    </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Do you have a designer who will prepare the layout of stickers and cards?</v-expansion-panel-header>
                    <v-expansion-panel-content>Yes, we provide such services, please contact us. contact us by <a href="mailto:guest@tourist.com">email</a></v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Can you help me customize the appearance of the app?</v-expansion-panel-header>
                    <v-expansion-panel-content>Yes, send an <a href="mailto:guest@tourist.com">email</a> request</v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Do you provide advice on how best to make an app for a hotel, camping, etc.?</v-expansion-panel-header>
                    <v-expansion-panel-content>Yes,  <a href="mailto:guest@tourist.com">email</a> us your request and we will help</v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
            </div>
          </div>
          <div style="padding-right:40px;"  v-else>
            <div class="help__questions">
              <v-expansion-panels accordion flat>

                <v-expansion-panel v-for="helpItem in settings.help" :key="helpItem.question">
                  <v-expansion-panel-header>{{helpItem.question}}</v-expansion-panel-header>
                  <v-expansion-panel-content style="padding:0 15px" v-html="helpItem.answer"></v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

<!--            <div class="help__feedback">-->
<!--                <h2 class="subtitle">{{$t('help.caption')}}</h2>-->
<!--                <v-form class="form"-->
<!--                    ref="form"-->
<!--                    @submit="validate"-->
<!--                >-->
<!--                    <div class="field field_half">-->
<!--                        <div class="control">-->
<!--                            <v-text-field solo outlined flat-->
<!--                                :label="$t('form[\'Ваше имя\']')"-->
<!--                                required-->
<!--                                :rules="nameRules"-->
<!--                                v-model="question.name"-->
<!--                                validate-on-blur-->
<!--                            ></v-text-field>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="field field_half">-->
<!--                        <div class="control">-->
<!--                            <v-text-field solo outlined flat-->
<!--                                :label="$t('form[\'E-mail\']')"-->
<!--                                required-->
<!--                                :rules="emailRules"-->
<!--                                v-model="question.email"-->
<!--                                validate-on-blur-->
<!--                            ></v-text-field>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                        <div class="control">-->
<!--                            <v-textarea class="textarea" solo outlined flat height="120"-->
<!--                                :label="$t('form[\'Ваше сообщение\']')"-->
<!--                                required-->
<!--                                :rules="messageRules"-->
<!--                                v-model="question.mesage"-->
<!--                                validate-on-blur-->
<!--                            ></v-textarea>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                        <div class="control">-->
<!--                            <v-btn type="submit" color="primary" block depressed>-->
<!--                                {{$t('help.btn')}}-->
<!--                            </v-btn>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </v-form>-->
<!--            </div>-->
        </div>

        <PopupSuccess ref="PopupSuccess" title="Success" :content="$t('help.success')"/>
        <PopupError ref="PopupError" :title="$t('help.error')"/>
    </main>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex';
import titleMixin from '@/mixins/titleMixin'

import PopupSuccess from '@/components/Popup/PopupSuccess.vue'
import PopupError from '@/components/Popup/PopupError.vue'

export default {
    name: 'Help',
    title() {
        return `${this.title}`
    },
    mixins: [titleMixin],
    components: {
        PopupSuccess,
        PopupError
    },
    beforeRouteEnter (to, from, next) {
        store.dispatch('help/getList')
            .then(() => {
                next();
            }).catch(() => {
                next();
            })
    },
    computed: {
        ...mapState(['help','settings']),
    },
    data() {
        return {
            title: this.$t('help.title'),
            question: {
                name: null,
                email: null,
                mesage: null,
            },

            nameRules: [
                v => !!v || this.$t('landing.valid["Имя обязателено"]'),
            ],
            emailRules: [
                v => !!v || this.$t('landing.valid["E-mail обязателен"]'),
                v => /.+@.+\..+/.test(v) || this.$t('landing.valid["E-mail должен быть валидным"]'),
            ],
            messageRules: [
                v => !!v || this.$t('landing.valid["Сообщение обязательно"]'),
            ],
            questions: [
                {id: 1, name: 'Как это работает?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 2, name: 'Что мне нужно для начала работы?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 3, name: 'Что если у меня закончились стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 4, name: 'Как добавить новый отель?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 5, name: 'Зачем нужны стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 6, name: 'Как правильно распечатать стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 7, name: 'Как это работает?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 8, name: 'Что мне нужно для начала работы?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 9, name: 'Что если у меня закончились стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 10, name: 'Что если у меня закончились стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 11, name: 'Зачем нужны стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
                {id: 12, name: 'Как правильно распечатать стикеры?', text: 'Для печати стикеров вам необходимо скачать специальный PDF файл с двумя типами стикеров и нажать кнопку печати в открытом виде файла.'},
            ]
        }
    },
    methods: {
        validate (e) {
            e.preventDefault();

            if (this.$refs.form.validate()) {
                this.$axios
                    .post('send-mesage', this.question)
                    .then(response => {
                        // console.log(response)
                        this.question = {
                            name: null,
                            email: null,
                            mesage: null,
                        };
                        this.$refs.PopupSuccess.open();
                    })
                    .catch(error => {
                        this.$refs.PopupError.open();
                    });
            }
        },
    }
};
</script>

<style lang="scss">
.help {
    @include row;
    align-items: flex-start;
}

.help__questions {
    border-radius: 5px;
    border: solid 1px #e4e4e4;
}

.help__feedback {
    padding: 30px;
    border-radius: 5px;
    border: solid 1px #e4e4e4;
}
</style>
